var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.name,
          id: "name",
          label: "Name",
          type: "text",
          error: _vm.errors.get("name")
        },
        on: {
          input: function($event) {
            return _vm.onNameInput($event)
          }
        }
      }),
      _vm.auth.isSuperAdmin
        ? _c(
            "ck-text-input",
            {
              attrs: {
                value: _vm.slug,
                id: "slug",
                label: "Unique slug",
                type: "text",
                error: _vm.errors.get("slug")
              },
              on: {
                input: function($event) {
                  return _vm.onInput("slug", $event)
                }
              }
            },
            [
              _c(
                "gov-hint",
                { attrs: { slot: "hint", for: "slug" }, slot: "hint" },
                [
                  _vm._v("\n      This will be used to access the collection."),
                  _c("br"),
                  _vm._v(
                    "\n      e.g. /collections/" + _vm._s(_vm.slug) + "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c("ck-loader")
        : !_vm.loading && _vm.appShowParentIdOnCollections
        ? _c("ck-select-input", {
            attrs: {
              value: _vm.parent_uuid,
              id: "parent_uuid",
              label: "Parent",
              options: _vm.categoryOptions,
              error: _vm.errors.get("parent_uuid")
            },
            on: {
              input: function($event) {
                return _vm.onInput("parent_uuid", $event)
              }
            }
          })
        : _vm._e(),
      _c("ck-textarea-input", {
        attrs: {
          value: _vm.intro,
          id: "intro",
          label: "Description of category",
          hint:
            "A short summary detailing what type of services the category contains.",
          maxlength: 300,
          error: _vm.errors.get("intro")
        },
        on: {
          input: function($event) {
            _vm.$emit("update:intro", $event)
            _vm.$emit("clear", "intro")
          }
        }
      }),
      _c("ck-image-input", {
        attrs: {
          id: "image",
          label: "Category image",
          "file-id": _vm.image_file_id,
          errors: _vm.errors
        },
        on: {
          input: function($event) {
            return _vm.onInput("image_file_id", $event.file_id)
          },
          "image-changed": function($event) {
            _vm.$emit("image-changed", $event)
            _vm.$emit("clear", "file")
          },
          "alt-text-changed": function($event) {
            _vm.$emit("alt-text-changed", $event)
            _vm.$emit("clear", "alt_text")
          }
        }
      }),
      _c("collection-homepage-input", {
        attrs: {
          value: _vm.homepage,
          id: "homepage",
          label: "Show the Category on the homepage",
          error: _vm.errors.get("homepage")
        },
        on: {
          input: function($event) {
            return _vm.onInput("homepage", $event)
          }
        }
      }),
      _c("collection-enabled-input", {
        attrs: {
          value: _vm.enabled,
          id: "status",
          type: "category",
          label: "Status of Category",
          error: _vm.errors.get("enabled")
        },
        on: {
          input: function($event) {
            return _vm.onInput("enabled", $event)
          }
        }
      }),
      _c("gov-heading", { attrs: { size: "m" } }, [_vm._v("Sideboxes")]),
      _c("gov-body", [
        _vm._v(
          "\n    Create up to three sideboxes that will can be used to display information\n    on the category results page.\n  "
        )
      ]),
      _c("ck-sideboxes-input", {
        attrs: { sideboxes: _vm.sideboxes, errors: _vm.errors },
        on: {
          input: function($event) {
            return _vm.onInput("sideboxes", $event)
          }
        }
      }),
      _c("gov-label", { staticClass: "govuk-!-font-weight-bold" }, [
        _vm._v("Taxonomies")
      ]),
      _c("ck-taxonomy-input", {
        attrs: {
          root: "categories",
          invalid: _vm.errors.has("category_taxonomies"),
          value: _vm.category_taxonomies,
          error: _vm.errors.get("category_taxonomies"),
          showCollections: false
        },
        on: {
          input: function($event) {
            return _vm.$emit("update:category_taxonomies", $event)
          },
          clear: function($event) {
            return _vm.$emit("clear", "category_taxonomies")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }